import {
  ComponentProps,
  Dispatch,
  ElementRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router";
import { useDebouncedCallback } from "use-debounce";

import { Thread, User } from "@utility-types";
import { Form } from "components/design-system/Forms";
import { MessageEditor } from "components/MessageEditor";
import { HistoryState } from "components/Navigation/HistoryState";
import { currentPathWithoutDrawer, routeToThread } from "components/routing/utils";
import { useValidateMessage } from "components/thread/hooks";
import { useSendMessage } from "components/threads/ThreadCompose/hooks";
import useSuggestSubject, {
  minSuggestLen,
} from "components/threads/ThreadCompose/hooks/useSuggestSubject";
import { Action, formToInput } from "components/threads/ThreadCompose/SendMessageReducer";
import { DraftForm } from "components/threads/ThreadCompose/types";
import useReadOnlyEditorState from "hooks/editor/useReadOnlyEditorState";
import { useGlueAIBot } from "hooks/glueAI/useGlueAIBot";
import useAuthData from "hooks/useAuthData";
import useComponentMounted from "hooks/useComponentMounted";
import useAppStateStore from "store/useAppStateStore";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import tw from "utils/tw";

import AIPromptSuggestions, { PromptSuggestion } from "../AIPromptSuggestions";
import useAIComposeDraft from "../hooks/useAIComposeDraft";
import useLlmModel from "../hooks/useLlmModel";

import AIMessageEditor from "./AIMessageEditor";

type FormData = DraftForm & {
  chatModel: string;
};

type Props = {
  glueAIBot?: User;
  isModal?: boolean;
  secondaryPane?: boolean;
} & Parameters<typeof useSendMessage>[0];

const MetadataWatcher = ({
  dispatch,
  metadata,
}: { dispatch: Dispatch<Action>; metadata: DraftForm["metadata"] }) => {
  const { watch } = useFormContext();
  useEffect(() => {
    const { unsubscribe } = watch(({ chatModel }) => {
      const newMetadata = { ...metadata, aiSettings: { chatModel } };
      dispatch({ type: "change", draftForm: { metadata: newMetadata } });
    });
    return () => unsubscribe();
  }, [dispatch, metadata, watch]);

  return null;
};

const AIComposeInner = ({ glueAIBot, initialDraft, isModal, secondaryPane }: Props) => {
  const { authData } = useAuthData();
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<PromptSuggestion | null>(null);

  const history = useHistory<HistoryState>();

  const editor = useRef<ElementRef<typeof MessageEditor> | null>(null);
  const { validateMessageToGlueAI } = useValidateMessage();

  useReadOnlyEditorState(editor);

  const { aiDraft, removeDraft } = useAIComposeDraft();
  const { defaultModel } = useLlmModel();

  const handleClose = useCallback(() => {
    if (secondaryPane) {
      history.push(currentPathWithoutDrawer());
    }
  }, [history, secondaryPane]);

  const onFinish = useCallback(
    (result?: Thread) => {
      if (!result) {
        handleClose();
        return;
      }

      removeDraft();

      history.replace(
        routeToThread({
          threadID: result.id,
          to: secondaryPane ? "secondary" : "primary",
        })
      );
    },
    [handleClose, history, removeDraft, secondaryPane]
  );

  const { compose, dispatch, sendDraft } = useSendMessage({
    initialDraft: {
      ...initialDraft,
      message: {
        text: aiDraft?.message.text ?? "",
        attachments: aiDraft?.message.attachments ?? [],
      },
      metadata: aiDraft?.metadata,
      recipients: glueAIBot ? [glueAIBot] : [],
      subject: aiDraft?.subject ?? "",
    },
    onFinish,
  });

  const isMounted = useComponentMounted();

  const handleSaveDraft = useCallback(() => {
    // save draft to local storage so that it can be restored at a later time;
    // only do this on desktop.
    if (!breakpointMD || !isMounted.current) return;
    useAIComposeDraft.setState({
      aiDraft: compose.draftForm,
    });
  }, [breakpointMD, compose.draftForm, isMounted]);

  const debouncedSaveDraft = useDebouncedCallback(handleSaveDraft, 500, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    debouncedSaveDraft();
  }, [debouncedSaveDraft, handleSaveDraft]);

  // cancel debounce when component unmounts
  useEffect(() => () => debouncedSaveDraft.cancel(), [debouncedSaveDraft]);

  // Callbacks

  const onMessageChange = useCallback(() => {
    if (!editor.current) return;
    const { text, attachments } = editor.current.getMessage();
    const appUnfurlSetups = editor.current.getAppUnfurlSetups();
    dispatch({
      type: "change",
      draftForm: { message: { text, attachments }, appUnfurlSetups },
    });

    if (!text.length) setSelectedCategory(null);
  }, [dispatch]);

  const onAttachFiles = useCallback((files: File[]) => {
    if (!editor.current) return;
    editor.current.addAttachments(files);
  }, []);

  // Disable submit button when pending or invalid content
  const readOnly = !!compose.pending;

  const submitDisabled = useMemo(
    () =>
      readOnly ||
      !validateMessageToGlueAI(
        formToInput({
          ...compose.draftForm,
        }),
        "send",
        false
      ),
    [compose.draftForm, readOnly, validateMessageToGlueAI]
  );

  useEffect(() => {
    editor.current?.setReadOnly(readOnly);
    editor.current?.setIsProcessing(submitDisabled);
  }, [readOnly, submitDisabled]);

  const defaultSubject = aiDraft?.subject ?? compose.draftForm?.subject ?? "";

  const setMessage = (message: string) => {
    editor.current?.setMessage({
      text: message,
      attachments: compose.draftForm.message.attachments,
    });
    compose.draftForm.message.text = message;
    editor.current?.focusEditor("end");
  };

  const onClickCategory = (category: PromptSuggestion) => {
    setSelectedCategory(category);
    setMessage(category.name);
  };

  const onClickSuggestion = (suggestion: string) => {
    setMessage(`${selectedCategory?.name} ${suggestion}`);
  };

  const { suggestSubject } = useSuggestSubject(compose);
  const handleSendDraft = () => {
    if (compose.draftForm.message.text.length > minSuggestLen) {
      suggestSubject?.()
        .then(suggestion => (compose.draftForm.subject = suggestion))
        .finally(() => sendDraft());
      return;
    }
    if (compose.draftForm.subject?.length === 0 && compose.draftForm.message.text.length === 0) {
      compose.draftForm.message.text = "Untitled thread";
    }
    sendDraft();
  };

  useEffect(() => {
    useNativeKeyboardStore.subscribe(({ keyboardHeight, state }) => {
      if (keyboardHeight > 100 || state === "opening" || state === "open") {
        setIsKeyboardOpen(true);
        return;
      }
      setIsKeyboardOpen(false);
    });
  }, []);

  return (
    <Form<FormData>
      className="relative flex flex-col items-center h-full w-full"
      useFormProps={{
        defaultValues: {
          chatModel: defaultModel,
          recipients: [glueAIBot],
          subject: defaultSubject,
        },
      }}
    >
      <div
        className={tw(
          "flex flex-col justify-end md:justify-center items-center h-full w-full gap-24 max-w-[800px] relative",
          isKeyboardOpen && "items-start"
        )}
      >
        <span className={tw("text-title-1", isKeyboardOpen && "hidden")}>
          What should we work on?
        </span>
        <div className="flex gap-24 flex-col-reverse md:flex-col w-full">
          <div
            className={tw(
              "min-h-[122px] max-h-[336px] min-w-[302px] w-full",
              "px-12 md:px-32 mb-[calc(env(safe-area-inset-bottom)_+_16px)] mt-64",
              "md:relative md:mb-0 md:mt-0",
              isKeyboardOpen && "!px-0 !mt-0 !mb-[calc(env(safe-area-inset-bottom))]"
            )}
          >
            <AIMessageEditor
              editor={editor}
              onChange={onMessageChange}
              onAttachFiles={onAttachFiles}
              sendDraft={handleSendDraft}
              selectedCategory={selectedCategory?.name}
              isModal={isModal}
              isReply={isKeyboardOpen}
              workspaceID={authData?.me.workspaceIDs[0]}
            />
          </div>
          <div className="h-auto md:h-[176px] px-12">
            <AIPromptSuggestions
              selectedCategory={selectedCategory}
              onClickCategory={onClickCategory}
              onClickSuggestion={onClickSuggestion}
            />
          </div>
        </div>
      </div>
      <MetadataWatcher dispatch={dispatch} metadata={compose.draftForm.metadata} />
    </Form>
  );
};

const AICompose: React.FC<Props> = (props: ComponentProps<typeof AICompose>) => {
  const glueAIBot = useGlueAIBot();

  // ensures the composer is never initialized without the glueAIBot user;
  // typically only an issue when the browser view is reloaded while the composer is open
  if (!glueAIBot) return null;

  return <AIComposeInner glueAIBot={glueAIBot} {...props} />;
};

export default AICompose;
