import { useEditorEvent } from "@remirror/react";
import { ComponentProps, FormEvent, useRef } from "react";

import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import FileUploadMenu from "components/FileUploadMenu";
import buttonStyles from "components/MessageEditor/components/buttonStyles";
import { isNativeAndroid } from "utils/platform";
import tw from "utils/tw";

type Props = {
  onAttachFiles: (newFiles: File[]) => void;
  readOnly?: boolean;
} & Pick<
  ComponentProps<typeof Button>,
  "tooltip" | "tooltipOpen" | "tooltipStyle" | "tooltipPlacement"
>;

const PaperClipButton = ({
  readOnly = false,
  tooltip = "Attach files",
  tooltipOpen,
  tooltipPlacement,
  tooltipStyle,
  onChange,
}: Pick<Props, "readOnly" | "tooltip" | "tooltipOpen" | "tooltipPlacement" | "tooltipStyle"> & {
  onChange: (event: FormEvent<HTMLInputElement>) => void;
}) => {
  const timestamp = Date.now();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Button
      buttonStyle="subtle"
      className={buttonStyles()}
      disabled={readOnly}
      onKeyPress={e => (e.key === "Enter" || e.key === " ") && inputRef.current?.click()}
      type="button"
      tooltip={tooltip}
      tooltipOpen={tooltipOpen}
      tooltipPlacement={tooltipPlacement}
      tooltipStyle={tooltipStyle}
    >
      <label
        className={tw(
          "flex overflow-hidden items-center max-w-full",
          !readOnly && "cursor-pointer"
        )}
        htmlFor={`file-upload${timestamp}`}
      >
        <Icon className="mr-0 shrink-0" icon="Paperclip" size={20} />
        <span className="sr-only">Attach files</span>
      </label>
      <input
        ref={inputRef}
        className="overflow-hidden absolute -z-1 w-0 h-0 opacity-0"
        disabled={readOnly}
        id={`file-upload${timestamp}`}
        multiple={true}
        onChange={onChange}
        tabIndex={-1}
        type="file"
      />
    </Button>
  );
};

const FileAttachButton = ({ onAttachFiles, ...props }: Props): JSX.Element => {
  const onChange = (event: FormEvent<HTMLInputElement>) => {
    event.currentTarget.files && onAttachFiles([...event.currentTarget.files]);
    event.currentTarget.value = "";
  };

  /* istanbul ignore useEditorEvent */
  useEditorEvent("paste", ({ clipboardData }) => {
    if (!clipboardData || clipboardData.files.length === 0) return;

    onAttachFiles([...clipboardData.files]);
  });

  return !isNativeAndroid() ? (
    <PaperClipButton onChange={onChange} {...props} />
  ) : (
    <FileUploadMenu
      onChange={onChange}
      placement="top-end"
      target={<PaperClipButton onChange={onChange} {...props} />}
      multiple
    />
  );
};

export default FileAttachButton;
